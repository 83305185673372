import React, { useState, useEffect, Fragment } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Row, Col } from 'reactstrap'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import backend from '../../services/backend'
import ReactTable from "react-table-6";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faFileVideo, faChevronCircleLeft } from '@fortawesome/free-solid-svg-icons'
import RecordingPlaybackModal from '../Modals/recordingPlaybackModal/RecordingPlaybackModal'
import RecordingProcessingModal from '../Modals/recordingProcessingModal/RecordingProcessingModal'
import EmailTranscriptView from '../emailTranscriptView/EmailTranscriptView'
import ChatTranscriptView from '../chatTranscriptView/ChatTranscriptView'
import WaitingModal from '../Modals/waitingModal/WaitingModal'
import AlertModal from '../Modals/alertModal/AlertModal'
import constants from '../../constants'
import moment from 'moment'
import utils from '../../services/utils'

import './ConversationDetails.css'
import "react-table-6/react-table.css";

const ConversationDetails = (props) => {
    const [error, setError] = useState()
    const [isWaitingModalOpen, setIsWaitingModalOpen] = useState(false)
    const [recordings, setRecordings] = useState([])
    const [conversation, setConversation] = useState()
    const [isRecordingPlaybackModalOpen, setIsRecordingPlaybackModalOpen] = useState(false)
    const [recordingPlayback, setRecordingPlayback] = useState()
    const [role, setRole] = useState()
    const history = useHistory()
    const { id } = useParams()
    const [isStillProcessing, setIsStillProcessing] = useState(true);
    const [isRecordingProcessingOpen, setIsRecordingProcessingOpen] = useState(false)

    useEffect(() => { // init load
        setRole(sessionStorage.getItem(constants.R2S_USER_ROLE));
        console.log('Conversation Details role is'+role);
        const fetchConversation = async () => {
            try {
                setIsWaitingModalOpen(true)
                let response = await backend.getConversation(sessionStorage.getItem(constants.GC_TOKEN), id)
                console.log('getConversation.response:', response)
                setConversation(response)
                if (response.recordings) {
                    let recordings = response.recordings;                    
                    let processedRecordings = [];
                    setIsRecordingProcessingOpen(true); //TODO: Remove for audio enhancement
                    for(let recording of recordings){
                        if(recording.relative_path && recording.status!= 'COMPLETED'){
                            setIsStillProcessing(true); //TODO: Remove for audio enhancement
                            console.log(`fetchProcessedRecordings:: Recording Response details ${JSON.stringify(recording)}`);
                            let apiCallSuccess = false
                            do{
                                let usertoken = sessionStorage.getItem(constants.GC_TOKEN);        
                                let s3path = recording.relative_path.replace('wav','opus');
                                try {                                    
                                    let apiResponse = await backend.getRecordingURLStatus(usertoken,s3path);
                                    console.log(`fetchProcessedRecordings:: API response for recording URL Status is ${JSON.stringify(apiResponse)}`);   
                                    if(apiResponse.convertionStatus && apiResponse.convertionStatus == 'COMPLETED'){
                                        apiCallSuccess = true; 
                                        recording.status = apiResponse.convertionStatus;
                                        recording.url = apiResponse.recURL;
                                    }else{
                                        apiCallSuccess = false; 
                                    }                                        
                                } catch (exception) {
                                    console.log(`fetchProcessedRecordings::Got Exception in get recording URL Status`);
                                    console.log(exception);
                                    apiCallSuccess = false;
                                }
                            
                                if(apiCallSuccess== false){
                                    console.log(`fetchProcessedRecordings:: will check recordings status after 10 seconds ${new Date()}`);
                                    await sleepFor(10*1000);
                                    console.log(`fetchProcessedRecordings:: will retry now ${new Date()}`);                                        
                                }
                            }while(apiCallSuccess == false);
                            processedRecordings.push(recording);
                        }else{
                            console.log(`recording.status is COMPLETED`);
                        }                    
                    }
                    if(processedRecordings && processedRecordings.length>0){
                        setRecordings(processedRecordings);
                        setIsStillProcessing(false);                    
                    }else{
                        console.log(`We dont have any processed recordings`);
                    }                               
                    setIsRecordingProcessingOpen(false); 
                }                    
            } catch (error) {
                console.log('fetchConversation:,', error)
                setError(`An error occured while fetching the conversation:${JSON.stringify(error.message)}`)
            } finally {
                setIsWaitingModalOpen(false)
            }
        }
        fetchConversation()
    }, [id])

    const handleRecordingClick = (recording) => {
        console.log('handleRecordingClick:', recording)
        setRecordingPlayback(recording)
        toggleRecordingPlaybackModal()
    }

    const toggleRecordingPlaybackModal = () => setIsRecordingPlaybackModalOpen(!isRecordingPlaybackModalOpen)

    const conditionallyRenderConversation = () => {
        if (!conversation) return        
        let media_type = 'Call';
        if(conversation.recordings && conversation.recordings.length>0){
            if(conversation.recordings[0]&& conversation.recordings[0].media_type){
                media_type = conversation.recordings[0].media_type;
                console.log(`Rendering Recording with Media type as ${media_type}`);
            }
        }
        switch (media_type.toLowerCase()) {
            case 'chat':
                return <Fragment>
                    {conversation.recordings &&
                        <ChatTranscriptView
                            id={conversation.conversation_id}
                            recordings={conversation.recordings}
                        />}
                </Fragment>

            case 'email':
                return <Fragment>
                    {conversation.recordings &&
                        <EmailTranscriptView
                            id={conversation.conversation_id}
                            recordings={conversation.recordings}
                        />}
                </Fragment>
            default: // voice
                return <Fragment>
                    <div className="px-2">
                    <Row>
                        <Col>
                            <span className="details-header">Conversation Recordings</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <ReactTable
                                showPageSizeOptions={true}
                                pageSizeOptions={[5, 10, 15, 20, 25, 50, 100]}
                                defaultPageSize={10}
                                minRows={0}
                                className="-highlight -striped table-container"
                                data={recordings}
                                columns={[
                                {
                                    Header: 'Play',
                                    headerClassName: 'headerColumn',
                                    Cell: row => {
                                        // switch (row.original.media_type.toLowerCase()) {
                                        //     case 'screen': return (
                                        //         <div className="div-center">
                                        //             <button onClick={() => { handleRecordingClick(row.original) }}>
                                        //                 <FontAwesomeIcon icon={faFileVideo} />
                                        //             </button>
                                        //         </div>
                                        //     )
                                        //     case 'call': 
                                        return (
                                                <div className="div-center">
                                                    <button onClick={() => { handleRecordingClick(row.original) }}>
                                                        <FontAwesomeIcon icon={faPlay} />
                                                    </button>
                                                </div>
                                        )
                                        //     default: return <Fragment></Fragment>
                                        // }
                                    }
                                }
                                ]}
                                getTdProps={(state, rowInfo, column, instance) => {
                                    return {
                                        style: {
                                            cursor: 'pointer'
                                        }
                                    }
                                }}
                                getTrProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                getTrGroupProps={() => {
                                    return {
                                        style: {
                                            flex: '0 0 auto'
                                        }
                                    }
                                }}
                                defaultFilterMethod={(filter, row) => {
                                    const id = filter.pivotId || filter.id;
                                    return (row[id] !== undefined ?
                                        String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
                                        : true
                                    );
                                }}
                            />
                        </Col>
                    </Row>
                </div>
                </Fragment>
        }
    }

    return (
        <div id="component-wrap">
            <Header role={role} />
            <div id="main">
                <WaitingModal
                    isOpen={isWaitingModalOpen}
                    header='Loading conversation details'
                />
                <RecordingProcessingModal
                    isOpen={isRecordingProcessingOpen}
                    header='Processing Recordings'
                />
                <AlertModal
                    isOpen={!!error}
                    header='Error'
                    toggle={() => { setError(undefined) }}
                    body={error}
                />

                <RecordingPlaybackModal
                    isOpen={isRecordingPlaybackModalOpen}
                    toggle={() => { toggleRecordingPlaybackModal() }}
                    recording={recordingPlayback}
                />

                <div className="px-2">
                    <Row>
                        <Col>

                            <button onClick={() => { history.push('/conversations') }}>
                                <FontAwesomeIcon className="fa-2x" icon={faChevronCircleLeft} />
                            </button>
                            <span className="conversations-details-header-text">Conversation Details - {conversation && conversation.conversation_id}</span>

                            <hr />
                        </Col>
                    </Row>
                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Start Date</span><span className="details-value">{conversation && moment(conversation.conversation_start).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                        <Col>
                            <span className="details-header">End Date</span><span className="details-value">{conversation && moment(conversation.conversation_end).format("MMM Do YYYY, h:mm:ss a")}</span>
                        </Col>
                    </Row>

                    <Row xs="2">
                        <Col>
                            <span className="details-header">ANI</span><span className="details-value">{conversation && conversation.ani}</span>
                        </Col>
                        <Col>
                            <span className="details-header">DNIS</span><span className="details-value">{conversation && conversation.dnis}</span>
                        </Col>
                    </Row>                    
                    <Row xs="2">
                        <Col>
                            <span className="details-header">Agent</span><span className="details-value">{conversation && conversation.agent_name}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Queue Name</span><span className="details-value">{conversation && conversation.queue_name}</span>
                        </Col>
                    </Row>                    
                    <Row xs="2">                        
                        <Col>
                            <span className="details-header">Conversation Direction</span><span className="details-value">{conversation && conversation.originating_direction}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Handle Duration</span><span className="details-value">{conversation && conversation.handle_duration && utils.formatMilliseconds(conversation.handle_duration)}</span>
                        </Col>
                    </Row>          
                    <Row xs="2">
                        <Col>
                            <span className="details-header">ACW Duration</span><span className="details-value">{conversation && conversation.acw_duration && utils.formatMilliseconds(conversation.acw_duration)}</span>
                        </Col>
                        <Col>
                            <span className="details-header">Call Duration</span><span className="details-value">{conversation && conversation.call_duration && utils.formatMilliseconds(conversation.call_duration)}</span>
                        </Col>
                    </Row>                      
                    <Row>
                        <Col>
                            <hr />
                        </Col>
                    </Row>
                </div>
                {conditionallyRenderConversation()}
            </div>
            <Footer />
        </div>
    )
}
const sleepFor = (ms) => {
    return new Promise(resolve => setTimeout(resolve, ms));
}
export default ConversationDetails